<template>
  <div>
    <b-modal
      title="Lista de servicios"
      v-model="show"
      no-close-on-backdrop
      hide-header-close
      size="md"
    >
      <b-row v-if="services != null && services.length >0">
        <b-col lg="12" v-for="(service, i) in services" v-bind:key="i">
          <CCard>
            <CCardBody>
              <strong>{{i+1}}</strong>  <br/>
              <strong>Proveedor:</strong> {{ service.usuario.fullname }} <br />
              <strong>Cliente:</strong> {{ service.mascota.cliente.nombre }} <br/>
              <strong>Fecha inicio:</strong> {{setDateFormat(service.fecha_inicio) }} <br/>
              <strong>Fecha fin:</strong> {{setDateFormat(service.fecha_fin) }}<br/>
              <strong>Tipo llegada:</strong> {{service.detalle_nas.tipo_inicio_servicio.nombre }}<br/>
              <strong>Delta minutos:</strong> {{service.detalle_nas.delta_minutos}}<br/>
              <strong>Duración:</strong> {{service.detalle_nas.duracion}}<br/>
              <!-- <strong>Fecha inicio:</strong> {{service.fecha_inicio }} <br/>
              <strong>Fecha fin:</strong> {{service.fecha_fin }}<br/> -->
            </CCardBody>
          </CCard>

        </b-col>
      </b-row>
      <template #modal-footer>
        <div class="w-100">
          <b-row>           
            <b-col lg="12">
              <b-button size="md" variant="danger" @click="hideModal">
                Cancelar
              </b-button>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import moment from 'moment'
  export default {
    name:"executionModal",
    props: ['show', 'services'],
    methods: {
      hideModal() {
        this.$store.commit("defShowAdminExecutionModal", false);
      },
      setDateFormat(date){
        return moment(date._i).add(5, 'hours').format('hh:mm a DD/MMMM')
      }

    }
  }
</script>

<style lang="scss" scoped>

</style>