<template>
  <div>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      background-color="#fff"
      :is-full-page="false"
    ></loading>
    <div v-for="error_message in errors" v-if="errors.length > 0">
      <CAlert color="danger" closeButton>{{error_message}}</CAlert>
    </div>
    <Modal
      :services="servicesToShow"
      :show="showModal"
      />

    <!-- Filters -->
    <section>
      <CCard>
        <CCardHeader>
          <strong>Filtros</strong>
          <div class="card-header-actions">
            <b-button @click="submit">Consultar</b-button>
          </div>
        </CCardHeader>
        <CCardBody>
          <b-row>
            <b-col lg="4">
              <b-form-group label="Tipo de fecha" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                  id="radio-group-1"
                  v-model="dateOptionSelected"
                  :options="optionsDate"
                  :aria-describedby="ariaDescribedby"
                  name="radio-options"
                ></b-form-radio-group>
              </b-form-group>
            </b-col>

            <b-col cols="4">
              <v-date-picker 
                mode="range" 
                v-model="selectedDate" 
                is-inline />
              <p>
                start: {{ selectedDate.start.toLocaleDateString() }}
                <br />
                end: {{ selectedDate.end.toLocaleDateString() }}
              </p>
            </b-col>

            <b-col lg="4">
              <b-form-group v-if="enableCity">
                <small>Ciudades</small>
                <v-select
                  v-model="citySelected"
                  :options="cities"
                  placeholder="Selecciona tu ciudad"
                  :class="{'form-control is-invalid': $v.citySelected.$error,
                                  'form-control is-valid': !$v.citySelected.$invalid}"
                ></v-select>
                <b-form-invalid-feedback
                  v-if="!$v.citySelected.required"
                >Debes seleccionar una ciudad</b-form-invalid-feedback>
              </b-form-group>
              
              <b-form-group>
                <small>Usuarios</small>
                <v-select
                  v-model="userSelected"
                  :options="usersComputed"
                  placeholder="Selecciona un usuario"
                  :class="{'form-control is-invalid': $v.userSelected.$error,
                                  'form-control is-valid': !$v.userSelected.$invalid}"
                ></v-select>
                <b-form-invalid-feedback
                  v-if="!$v.userSelected.required"
                >Debes seleccionar un usuarios</b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </CCardBody>
      </CCard>
    </section>

    <CompanyKPIs/>

    <!-- Tables -->
    <section>
      <CCard>
        <CCardHeader>
          <strong>Tablas</strong>
        </CCardHeader>
        <CCardBody>
          <b-row>
            <b-col lg="12">
              <b-table
                :items="items"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :filter-included-fields="filterOn"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                stacked="md"
                show-empty
                small
                @filtered="onFiltered"
                :selectable="true"
                @row-clicked="rowClicked"
              >
                <!-- <template #cell(2021-12)="row">
                  <div v-if="row.index == 1 || row.index == 3
                  || row.index == 5 || row.index == 7">
                    <b-link @click="showServiceDetailModal(row.item)">{{ row }}</b-link>
                  </div>
                  <div v-else>
                    {{ row.value }}
                  </div>
                </template> -->

                <template #cell(name)="row">
                  {{ row.value }}
                  <!-- {{ row.value.first }} {{ row.value.last }} -->
                </template>

                <template #cell(actions)="row">
                  <b-row>
                    <b-col lg="12">
                      <b-button
                        size="sm"
                        @click="openDetail(row.item, row.index, $event.target)"
                        class="mr-1"
                      >Ver servicios</b-button>
                    </b-col>
                  </b-row>
                </template>

                <!-- Details column -->
                <template #row-details="row">
                  <b-card bg-variant="light">
                    <b-row>
                      <b-col lg="4" md="6">
                        <strong>Id:</strong>
                        {{ row.item.id }}
                      </b-col>
                      <b-col lg="4" md="6">
                        <strong>Fecha de pago:</strong>
                        {{ row.item.fecha_operacion }}
                      </b-col>
                      <b-col lg="4" md="6">
                        <strong>Cantidad servicios:</strong>
                        {{ row.item.cantidad }}
                      </b-col>
                      <b-col lg="4" md="6">
                        <strong>Medio de pago:</strong>
                        {{ row.item.medio }}
                      </b-col>
                    </b-row>
                  </b-card>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </CCardBody>
      </CCard>
    </section>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import { DatePicker } from "v-calendar";
import { required } from "vuelidate/lib/validators";
import Modal from "./execution/Modal";
import moment from "moment";
import CompanyKPIs from './execution/CompanyKPIs';

const today = new Date();
export default {
  name: "adminExecution",
  components: {
    Loading,
    "v-date-picker": DatePicker,
    Modal,
    CompanyKPIs
  },
  data() {
    return {
      loading: true,
      errors: [],
      dateOptionSelected: "mes",
      optionsDate: [
        { text: "Mes", value: "mes" },
        { text: "Día", value: "día" }
      ],
      //selectable day
      selectedDate: {
        start: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
        end: new Date(today.getFullYear(), today.getMonth(), today.getDate())
      },
      users: [{ value: 0, label: "Todos" }],
      userSelected: null,
      //Table atributes
      cities: [{value: 0, label: "Todas"}],
      citySelected: {value: 0, label: "Todas"},
      enableCity: true,
      items: [],
      fields: [
        { key: "kpi", label: "Indicador" },        
        // { key: "actions", label: "Acciones" }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      dismissCountDown: 4,
      dismissSecs: 4,
      serviceDetail: null,
      servicesToShow: null,
      showModal: false
    };
  },
  validations: {
    userSelected: {
      required
    },
    citySelected: {
      required
    }
  },
  created() {
    this.userProfile = JSON.parse(this.$store.getters.userProfile);
    // console.log(this.userProfile);
    this.watchs()
    if (this.userProfile.status.id == 2) {
      
      this.fetchCities();  
      // this.fetchWalkers();
    } else {
      this.errors.push("No tienes acceso a este módulo");
    }
    this.loading = false;
  },
  computed: {    
    sortOptions: {
      get() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key };
          });
      },
      set(newValue) {
        return newValue
      }      
    },
    usersComputed: {
      get(){
        let citySelected = this.citySelected;                
        if(citySelected.value == 0){
          return this.users
        } else {          
          let usersFiltered = []
          usersFiltered = this.users.filter(user => user.cityId == citySelected.value)          
          let allIds = ''
          usersFiltered.map((user, i) => {
            allIds = allIds + user.value
            if(i < usersFiltered.length -1) {
              allIds = allIds + ','
            }
          })
          usersFiltered.unshift({
            value: allIds,
            label: 'Todos'
          })
          this.userSelected={
            value: allIds,
            label: 'Todos'
          }
          return usersFiltered
        }
      },
      set(newValue){
        return newValue
      }
    }  
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  
  methods: {
    fetchCities() {
      let getCities = this.$firebase.functions().httpsCallable('getCities')
      getCities().then((response) => {
        if(response.data != null) {
          let cities = response.data.data          
          if(this.userProfile.acceso.id == 1) {
            //owner
            cities.map((city,i) => {            
              this.cities.push({
                value: city.id,
                label: city.nombre,
              })
            })
          } else if (this.userProfile.acceso.id == 2) {
            //admin            
            let cityFound = cities.find(city => city.nombre == this.userProfile.ciudad)            
            this.citySelected  = {
              value: cityFound.id,
              label: cityFound.nombre
            } 
            this.cities = [{
              value: cityFound.id,
              label: cityFound.nombre
            } ]

          }
           this.fetchWalkers();
        }        
      }).catch(error => console.log(error))
    },
    fetchWalkers() {
      let payload= {
        city: {
          value: this.userProfile.ciudades.id,
          label: this.userProfile.ciudades.nombre
        }
      };      
      let getWalkers = null
      if(this.userProfile.acceso.id == 1){ //1: owner
        getWalkers = this.$firebase.functions().httpsCallable('getWalkers')
      }else if (this.userProfile.acceso.id == 2){ //2: admin
        getWalkers = this.$firebase.functions().httpsCallable('getWalkersByCity')
      }
      
      getWalkers(payload).then((response) => {
        let users = response.data.data    
        this.setProviders(users);
      }).catch((error) => {
        console.log(error)
      })
    },
    setProviders(users) {
      let allIds = ''
      users.map((user, i) => {        
        allIds = allIds + user.id
        if(i < users.length -1) {
          allIds = allIds + ','
        }
        
        let item = {
          value: user.id,
          label: user.fullname,
          cityId: user.ciudades.id
        }
        this.users.push(item)
      })      
      this.userSelected= {
        value: allIds,
        label: 'Todos'
      }      
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.error = true;
        this.errors = ["No has completado correctamente los filtros"];
      } else {
        this.loading = true
        this.fetchKpis();
      }
    },
    fetchKpis() {
      
      let dateStart = null;
      let dateEnd = null;

      dateStart = moment(this.selectedDate.start).format("yyyy-MM-DD")
      dateEnd = moment(this.selectedDate.end).format("yyyy-MM-DD")            

      let data = {
        dateStart,
        dateEnd,
        dateOptionSelected: this.dateOptionSelected,
        user: this.userSelected,                        
      }
      let getAdminKpis = this.$firebase.functions().httpsCallable("getAdminKpis");
      getAdminKpis(data).then(response => {
        let data = response.data.data;
        // console.log(data);
        this.fields = data.fields;
        this.items = data.items;
        this.serviceDetail = data.servicesDetail
      }).catch(error => {
        console.log(error);
      }).finally(()=> {
        this.loading = false
      })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    showServiceDetailModal(item){
      console.log(item)
    },
    rowClicked (item, index) {
      // console.log(index)
      // console.log(item)
      switch (index) {
        case 1://services late
          this.servicesToShow = (this.serviceDetail.servicesLate).sort((a,b) => a.usuario - b.usuario)
          this.showModal = true
          break;
        case 3://services early
          this.servicesToShow = this.serviceDetail.servicesEarly    
          this.showModal = true
          break;
        case 4://services short time
          this.servicesToShow = this.serviceDetail.servicesShortTime 
          this.showModal = true
          break;
        case 7://services longer distance
          this.servicesToShow = this.serviceDetail.servicesLongerDistance   
          this.showModal = true
          break;
        case 8://Services without start gps
          this.servicesToShow = this.serviceDetail.servicesNoStartGPS   
          this.showModal = true
          break;
        case 9://Services without pic
          this.servicesToShow = this.serviceDetail.servicesNoPics
          this.showModal = true
          break;
        case 10://Services without pic
          this.servicesToShow = this.serviceDetail.servicesNoComments
          this.showModal = true
          break;
      
        default:
          this.showModal = false
          break;
      }
      
      
    },
    watchs() {
      this.$store.subscribe((mutation, state) => {
        if (mutation.type === "defShowAdminExecutionModal") {
          this.showModal = this.$store.getters.showAdminExecutionModal
        }
      });
    }
  }
};
</script>